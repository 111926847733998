import logo from './logo.svg';
import React from 'react';
import './App.css';

function App() {
  return (
      <div className="App">
          <header className="App-header">
              <img src="image/deloitte.jpg" alt="Your Photo" className="App-photo" style={{ width: '400px' }}/>

              <h1></h1>
              <p>Web Developer | IT Enthusiast</p>
          </header>
          <section className="App-section">
              <h2>About Me</h2>
              <p>
              Welcome to my personal website! I am passionate about IT and web development.
            This is a simple React web app showcasing my interests and skills.
          </p>
        </section>
        <section className="App-section">
          <h2>Technical Skills</h2>
            <ul>
                <li>Java</li>
                <li>JavaScript</li>
                <li>React</li>
                <li>React Native</li>
                <li>HTML/CSS</li>
                {/* Add more skills as needed */}
            </ul>
        </section>
        {/* Add more sections for your projects, education, experience, etc. */}
          <footer className="App-footer">
              <p>Contact me at: sophiecaoxin@gmail.com</p>
              <p>LinkedIn: https://www.linkedin.com/in/sophie-cao/</p>
              <p>Github: https://github.com/Sophie-coffee-addict</p>
          </footer>
      </div>
  );
}

export default App;
